body {
  background-color: #ededed;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.painel {
  margin: auto;
  background-color: rgba(255, 255, 255, 0.84);
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 10px 1em rgba(0, 0, 0, 0.5);
  width: 650px;
}

.Login #login .painel {
  padding: 0 15px 15px 15px;
}

.Login .login-footer {
  color: #8070d4;
}


::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #8070d4;
}


select {
  height: 35px!important;
}