#Reativar {
    background-color: #FFF;
}

#Reativar .div_paymentforms img {
    padding:5px;
}


#Reativar {
    text-align: left !important;
}

.hide {
    display: none;
}

#Reativar .radio {
    float:left;
    padding: 0 0 0 0;
    align-items: center;
    display: flex;
}


#Reativar .radio label {
   margin: 10px;
}